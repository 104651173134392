import {
  useCollections,
  useTrendingCollections,
  useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import { paths } from '@reservoir0x/reservoir-sdk'
import { Head } from 'components/Head'
import Layout from 'components/Layout'
import { Footer } from 'components/home/Footer'
import { Box, Button, Flex, Text } from 'components/primitives'
import { ChainContext } from 'context/ChainContextProvider'
import { useMarketplaceChain, useMounted } from 'hooks'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import Link from 'next/link'
import {
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useState,
} from 'react'
import supportedChains, { DefaultChain } from 'utils/chains'
import { hideFromHomepage } from 'utils/collections'

import * as Tabs from '@radix-ui/react-tabs'
import CollectionsTimeDropdown, {
  CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import LoadingSpinner from 'components/common/LoadingSpinner'
import MintsPeriodDropdown, {
  MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { FeaturedCards } from 'components/home/FeaturedCards'
import { TabsContent, TabsList, TabsTrigger } from 'components/primitives/Tab'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { MintRankingsTable } from 'components/rankings/MintRankingsTable'
import { useTheme } from 'next-themes'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import { contractDefinition, CollectionGroup } from 'utils/collections'

enum MainCollectionOrder {
  '0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42', // Wizards
  '0x251b5f14a825c537ff788604ea1b58e49b70726f', // Souls
  '0x9690b63eb85467be5267a3603f770589ab12dc95', // Warriors
}

type TabValue = 'collections' | 'mints'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>

type CollectionSchema = ReturnType<typeof useCollections>['data']

const fetchData = async ({
  sortByTime,
}: {
  sortByTime: CollectionsSortingOption
}): Promise<CollectionSchema> => {
  let _collections: CollectionSchema = []
  for (let i = 0; i < supportedChains.length; i++) {
    const chain = supportedChains[i]
    let filterKey: paths['/collections/v7']['get']['parameters']['query']['sortBy']
    switch (sortByTime) {
      case '30d':
        filterKey = '30DayVolume'
        break
      case '7d':
        filterKey = '7DayVolume'
        break
      case '1d':
        filterKey = '1DayVolume'
        break
    }

    const collectionQuery: paths['/collections/v7']['get']['parameters']['query'] =
      {
        sortBy: filterKey,
        limit: 20,
      }

    if (chain.collectionSetId) {
      collectionQuery.collectionsSetId = chain.collectionSetId
    } else if (chain.community) {
      collectionQuery.community = chain.community
    }

    // get the main collection
    const response = await fetcher(
      `${chain.reservoirBaseUrl}/collections/v7`,
      collectionQuery,
      {
        headers: {
          'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
      }
    )

    if (response.data.collections && Array.isArray(response.data.collections)) {
      _collections.push(...response.data.collections)
    } else {
      // Handle the case where collections is not as expected
      console.error(
        'Collections is not an array or undefined',
        response.data.collections
      )
      // Optionally push an empty array or set a default value
      _collections.push(...[])
    }
  }

  // Filter out collections that are in the hideFromHomepage list
  const filteredCollections = _collections.filter(
    (collection) =>
      !hideFromHomepage.includes(
        collection.primaryContract?.toLowerCase() || ''
      )
  )

  // Map collections to include their group based on the contractDefinition
  const groupedCollections = filteredCollections.map((collection) => {
    const definition = contractDefinition.find(
      (def) =>
        def.contract.toLowerCase() === collection.primaryContract?.toLowerCase()
    )
    return {
      ...collection,
      group: definition ? definition.group : 'unknown', // Default to 'unknown' if no match is found
    }
  })

  const mainCollection = new Array(3)

  // Group collections by their group property
  const collectionsByGroup = groupedCollections.reduce((acc, collection) => {
    const { id, group } = collection

    if (id && Object.keys(MainCollectionOrder).includes(id)) {
      //@ts-ignore
      mainCollection[MainCollectionOrder[id]] = collection

      return acc
    }

    //@ts-ignore
    if (!acc[group]) {
      //@ts-ignore
      acc[group] = []
    }
    //@ts-ignore
    acc[group].push(collection)
    return acc
  }, {})

  //@ts-ignore
  collectionsByGroup['characters'] = [
    ...mainCollection,
    //@ts-ignore
    ...collectionsByGroup['characters'],
  ]

  console.log(collectionsByGroup)

  return collectionsByGroup as CollectionSchema
}

const Home: NextPage<Props> = ({ ssr }) => {
  const router = useRouter()
  const marketplaceChain = useMarketplaceChain()
  const isMounted = useMounted()

  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)

  const [collections, setCollections] = useState<CollectionSchema>(
    ssr.collection
  )

  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])

  const isSSR = typeof window === 'undefined'
  const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

  const [tab, setTab] = useState<TabValue>('collections')
  const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('1d')

  const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

  const updateSortByTime = async (sortByTime: CollectionsSortingOption) => {
    setSortByTime(sortByTime)
    setCollections(await fetchData({ sortByTime }))
  }

  let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
    limit: 20,
    period: sortByPeriod,
    type: 'any',
  }

  const { chain, switchCurrentChain } = useContext(ChainContext)

  const {
    data: trendingCollections,
    isValidating: isTrendingCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'volume',
      period: sortByTime,
    },
    chain.id,
    {
      //@ts-ignore
      fallbackData: ssr.trendingCollections,
      keepPreviousData: true,
    }
  )

  const {
    data: featuredCollections,
    isValidating: isFeaturedCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'sales',
      period: '24h',
    },
    chain.id,
    {
      //@ts-ignore
      fallbackData: ssr.featuredCollections,
      keepPreviousData: true,
    }
  )

  const { data: trendingMints, isValidating: isTrendingMintsValidating } =
    useTrendingMints({ ...mintsQuery }, chain.id, {
      //@ts-ignore
      fallbackData: ssr.trendingMints,
      keepPreviousData: true,
    })

  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = 'allTime'

  switch (sortByTime) {
    case '30d':
      volumeKey = '30day'
      break
    case '7d':
      volumeKey = '7day'
      break
    case '1d':
      volumeKey = '1day'
      break
  }

  return (
    <Layout>
      <Head />
      <Box
        css={{
          p: 24,
          height: '100%',
          '@bp800': {
            px: '$5',
          },
          '@xl': {
            px: '$6',
          },
        }}
      >
        <Box
          css={{
            mb: 64,
          }}
        >
          <Box
            css={{
              height: '100%',
            }}
          >
            {/* @ts-ignore */}
            <FeaturedCards collections={collections.characters} />
          </Box>
        </Box>

        <Tabs.Root
          onValueChange={(tab) => setTab(tab as TabValue)}
          defaultValue="collections"
        >
          {/* <Flex justify="between" align="start" css={{ mb: '$3' }}>
            <Text style="h4" as="h4">
              Trending
            </Text>
            {!isSmallDevice && (
              <Flex
                align="center"
                css={{
                  gap: '$4',
                }}
              >
                {tab === 'collections' ? (
                  <CollectionsTimeDropdown
                    compact={isSmallDevice && isMounted}
                    option={sortByTime}
                    onOptionSelected={(option) => {
                      setSortByTime(option)
                    }}
                  />
                ) : (
                  <MintsPeriodDropdown
                    option={sortByPeriod}
                    onOptionSelected={setSortByPeriod}
                  />
                )}
              </Flex>
            )}
          </Flex> */}
          {/* <TabsList css={{ mb: 24, mt: 0, borderBottom: 'none' }}>
            <TabsTrigger value="collections">Collections</TabsTrigger>
            <TabsTrigger value="mints">Mints</TabsTrigger>
          </TabsList> */}
          {isSmallDevice && (
            <Flex
              justify="between"
              align="center"
              css={{
                gap: 24,
                mb: '$4',
              }}
            >
              <Flex align="center" css={{ gap: '$4' }}>
                <CollectionsTimeDropdown
                  compact={isSmallDevice && isMounted}
                  option={sortByTime}
                  onOptionSelected={updateSortByTime}
                />
              </Flex>
            </Flex>
          )}
          <TabsContent value="collections">
            <Box
              css={{
                height: '100%',
              }}
            >
              <Flex direction="column">
                {isSSR || !isMounted ? null : (
                  <CollectionRankingsTable
                    collections={collections || []}
                    volumeKey={volumeKey}
                    loading={isTrendingCollectionsValidating}
                  />
                )}
                <Box
                  css={{
                    display: isTrendingCollectionsValidating ? 'none' : 'block',
                  }}
                ></Box>
              </Flex>
            </Box>
          </TabsContent>
          <TabsContent value="mints">
            <Box
              css={{
                height: '100%',
              }}
            >
              <Flex direction="column">
                {isSSR || !isMounted ? null : (
                  <MintRankingsTable
                    mints={trendingMints || []}
                    loading={isTrendingMintsValidating}
                  />
                )}
                <Box
                  css={{
                    display: isTrendingCollectionsValidating ? 'none' : 'block',
                  }}
                ></Box>
              </Flex>
            </Box>
          </TabsContent>
        </Tabs.Root>
      </Box>

      {/* <Footer /> */}
    </Layout>
  )
}

type TrendingCollectionsSchema =
  paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
  paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
  ssr: {
    collection: CollectionSchema
  }
}> = async () => {
  return {
    props: { ssr: { collection: await fetchData({ sortByTime: '1d' }) } },
  }
}

export default Home
